@charset "utf-8";

/*---------------------------------
	bit Common CSS 2018.2
	CSS inoue Document 2018.02
	Last Update 2018.02.00 inoue

・共通設定
・枠設定
・ヘッダ
・グローバルナビ
・コンテンツ部
・サイドナビ
・フッタ
---------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Aleo');

/*####################　共通設定　####################*/
*{
  box-sizing:border-box;
}

html {
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  height: 100%;
  clear: both;
}

body {
  line-height: 1.75;
  text-align: center;
  font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
  font-feature-settings: "palt";
  -moz-font-feature-settings: "palt";
  -webkit-font-feature-settings: "palt";
  letter-spacing: 0.045em;
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
  font-weight: 500;
  color: #000;
}

p {
  text-align: justify;
  text-justify: inter-ideograph;
}

a {
  color: #000;
  text-decoration: 　underline;
  &:hover {
    text-decoration: none;
  }
}

hr, .showBox {
  display: none;
}

#Wall {
  height: 0;
}

#Header, #GlobalNav, #Container {
  margin: 0 auto;
  text-align: left;
}

#Main {
  min-height: 500px;
  _height: 500px;
}

#Footer {
  margin: 0 auto;
  background-color: #00375a;
  text-align: left;
}

#Container:after, .contBox:after {
  content: "";
  display: block;
  clear: both;
}

/*　パンくず　※ブラウザ幅からはみ出たら...が出るタイプ
pc,sp共部分
-------------------------------------*/
#TopicPath {
  position: absolute;
  width: 100%;
  min-width: 1000px;
  bottom: 30px;
  padding-left: 200px;
  text-align: right;
  ol {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
    margin-right: 30px;
    list-style: none;
    line-height: 1;
    font-family: helvetica,'arial black',arial,sans-serif;
    text-align: right;
    font-size: 12px;
    color: #fff;
    &:after {
      content: "";
      display: block;
      clear: both;
    }
    li {
      display: inline;
      margin-left: 8px;
      font-family: "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
      font-weight: 500;
      font-size: 12px;
      color: #fff;
      text-align: left;
      &:before {
        content:"＞";
        margin-right: 7px;
      }
      &.home {
        padding-left: 0;
        background: none;
        &:before {
          content: "";
        }
      }
      &:last-of-type {
            overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -webkit-text-overflow: ellipsis;
      }
      a {
        text-decoration:underline;
        color: #fff;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

/* アコーディオン用css */
/*#################### PC ####################*/
  body {
    min-width: 1000px;
    font-size: 15px;
  }
  p {
    margin: 0 0 20px;
    line-height: 1.75;
    font-size: 16px;
  }
  a {
    &[href^="tel"] {
      text-decoration: none;
      &:hover {
        cursor: text;
      }
    }
    &:hover {
      text-decoration: none;
    }
  }
  .click {
    cursor: pointer;
  }
  .pc_display,.disp_pc {
    display: block;
  }
  .sp_display, .sp_display_inline,.disp_sp {
    display: none;
  }
  /*####################　枠設定　####################*/
  #Header,#Container {
    min-width: 1000px;
  }

  .innerBasic{
    width:1000px;
    margin:0 auto;
  }
  #Footer {
    min-width: 1000px;
  }
  /*####################　ヘッダ　####################*/
  #Header{
    position: relative;
    height: 106px;
    background: url(../img/header/logo.png) top left no-repeat;
    width: 100%;
    min-width: 1000px;
    h1{
      position: absolute;
      top: 25px;
      left: 30px;
      z-index: 999;
      a{
        display: block;
        width: 210px;
        height: 56px;
        text-indent: -9999px;
      }
    }
  }

body.fixed #Header {
  position: fixed;
  width: 100%;
  min-width: 1000px;
  z-index: 99;
  height: 70px;
  background-image: none;
  background-color: #fff;
  box-shadow:0px 0px 4px 1px #e8e8e8;
  h1 {
    top: 22px;
    z-index: 999;
    a {
      display: block;
      background-image: url(../img/header/logo-fixed.png);
      width: 294px;
      height: 24px;
      background-size: 294px auto;
    }
  }
}


  /*####################　グローバルナビ　####################*/
  #GlobalNav{
    position: absolute;
    top: 55px;
    left:0px;
    width: 100%;
    min-width: 1000px;
    z-index: 99;
    ul {
      padding-right: 30px;
      width: 100%;
      text-align: right;
      font-size: 0;

      li {
        margin-left:38px ;
        display: inline-block;
        font-size: 18px;
        a {
          color: #000;
          text-decoration: none;
          &:hover {
            opacity: 0.7;
          }
        }
        &:nth-of-type(1) {
          margin-left: 0;
        }
      }
    }
  }

body.fixed #GlobalNav {
  position: absolute;
  z-index: 990;
  top: 22px;
  ul {
    li {
      margin-left:35px ;
      font-size: 16px;
    }
  }
}

  /*####################　サブメニュー　####################*/
  #Sub {}
  /*####################　コンテンツ部　####################*/
  #MainImg {
      position: relative;
      width: 100%;
      h2 {
        position: absolute;
        bottom:22px;
        left:30px;
        font-size: 40px;
        font-weight: 700;
        text-align: left;
        line-height: 1.35;
        letter-spacing: 0.1em;
        color: #fff;
        span {
          display: block;
          margin-left: -4px;
          font-family: 'Aleo', serif;
          font-weight: 400;
          font-size: 20px;
          text-transform:uppercase;
          letter-spacing: 0.06em;
        }
      }
  }
  #Main {
    h3 {
      font-size: 32px;
      font-weight: bold;
      text-align: center;
      span{
        display: block;
        font-family: 'Aleo', serif;
        font-weight: 400;
        font-size: 14px;
        color: #004da1;
        text-transform:uppercase;
        letter-spacing: 0.05em;
        }
    }
      ul.pageLink{
        margin-bottom: 74px;
        text-align: center;
        li{
          display: inline-block;
          margin-right: 48px;
          a{
            padding-left: 18px;
            background: url(../img/contents/arrow_01.png) no-repeat left center;
            text-decoration: none;
            &:hover{
              text-decoration: underline;
            }
          }
          &:last-of-type {
            margin-right: 0;
          }
        }
        &:last-child{
          padding-top: 75px;
          margin-bottom: 244px;
          li{
            a{
              background: url(../img/contents/arrow_02.png) no-repeat left center;
            }
          }
        }
      }
  }


  /*####################　フッタ　####################*/
  #Footer {
    padding-top: 70px;
    .innerBasic {
      &:after {
        content:"";
        display: block;
        clear: both;
      }
      a {
        text-decoration: none;
        color: #fff;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    #LinkBox01 {
      float: left;
      padding-top: 5px;
      ul {
        li {
          margin-bottom: 20px;
          font-size: 16px;
          &:nth-of-type(5),
          &:nth-of-type(6) {
            a {
              &:after {
                content:url(../img/footer/arrow.png);
                margin-left: 10px;
              }
            }

          }
        }
      }
    }
    #LinkBox02 {
      float: right;
      width: 710px;
      padding-top: 5px;
      border-left:1px solid #295069;
      h3 {
        margin: 0 0 28px 45px;
        font-size: 16px;
      }
      ul {
        display: inline-block;
        margin: 0 0 0 45px;
        width: 306px;
        vertical-align: top;
        font-size: 14px;
        ul {
          margin: 6px 0 26px 0;
          li {
            position: relative;
            margin-bottom: 9px;
            padding-left: 11px;
            line-height: 1.4;
            &:before {
              position: absolute;
              top: 0;
              left: 0;
              content:"-";
              color:#bcbcbc;
            }
          }
        }
        li {
          &:last-of-type {
            ul {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    #CopyRight {
      position: relative;
      margin-top: 70px;
      height: 80px;
      background-color: #0c2844;
      h2 {
        display: none;
      }
      .innerBasic {
        position: relative;
      a {
        position: absolute;
        top: 26px;
        left: 0;
        &:hover {
          opacity: 0.7;
        }
      }
      small {
        position: absolute;
        top: 33px;
        right: 0px;
        font-family: 'Aleo', serif;
        font-weight: 400;
        font-size: 10px;
        text-transform:uppercase;
        color: #334c66;
      }
      }
      #PageTop {
        position: absolute;
        right: 0;
        bottom: 0;
        a {
          display: block;
          width: 80px;
          height: 80px;
          background: url(../img/footer/pagetop.png) center center no-repeat;
          background-color: #0a223a;
          text-indent: -9999px;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }



/*end*/